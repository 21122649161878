<template>
  <div class="container">
    <div class="card overflow-y overlapping-container has-top-shadow">
      <div class="card-content pb-6 px-4 pt-1">
        <CardNavigation/>
        <div class="container has-text-centered mb-3">
          <div class="py-4 is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            {{ t(`Leave ${$store.state.uiMods.game.toLowerCase()}`) }}
          </div>
        </div>
        <!-- <div class="container has-text-centered mb-3">
          <Inventory1/>
        </div> -->
        <div class="container has-text-centered">
          <div class="is-size-6 has-text-grey mb-4 px-4">
            {{ t(`You can re-enter the ${$store.state.uiMods.game.toLowerCase()} at any point of time by going back to the lobby via the shared link.`) }}
          </div>
          <a
            @click="leaveGame()"
            class="button has-text-weight-semibold is-medium is-danger"
            >
              <i class="fas fa-sign-out-alt mr-2"></i>
              Leave
            </a>
        </div>
        <div class="container has-text-centered mt-6">
          <div class="has-text-grey-light is-size-7 mb-2">
            App version: {{ appVersion }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
// import Inventory1 from '@/components/svgs/Inventory1'

export default {
  name: 'Exit',
  components: {
    // Inventory1,
    CardNavigation
  },
  data(){
    return {
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    appVersion() {
      return this.$store.state.appVersion
    }
  },
  methods: {
    leaveGame() {
      if (this.adventureTeam.redirectUrl) {
        window.location = this.adventureTeam.redirectUrl
      } else {
        this.$router.push({ name: 'Start' })
      }
    }
  }
}
</script>
